<script setup>
import {
  ref,
  inject,
  computed,
  toRefs,
  watch,
  onMounted,
  onUnmounted,
  defineAsyncComponent,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import googleAuth from '@/services/google-auth'
import twitterAuth from '@/services/twitter-auth'
import Confirm from '@/components/Confirm.vue'
import ButtonComponent from './ButtonComponent.vue'
import { useMixpanel } from '@/composables/mixpanel'
import ErrorAlert from '@/components/ErrorAlert.vue'
import safeGenerateRecaptchaToken from '@/helpers/safeGenerateRecaptchaToken.js'
import SvgIcon from '@/components/SvgIcon.vue'

const SmsVerificationDialog = defineAsyncComponent(
  () => import('@/components/SmsVerificationDialog.vue'),
)
const IntlTelInput = defineAsyncComponent(
  () => import('intl-tel-input/vueWithUtils'),
)

const mixpanel = useMixpanel()

const $axios = inject('axios')
const $store = useStore()
const $route = useRoute()
const $router = useRouter()

const email = ref($store.state.email)
const password = ref($store.state.password)

const tempGender = computed(() => $store.state.tempGender)

watch(email, (newEmail) => {
  $store.commit('SET_EMAIL', newEmail)
})

watch(password, (newPassword) => {
  $store.commit('SET_PASSWORD', newPassword)
})

onMounted(() => {
  const errorMessage = $route.query.errorMessage
  if (!errorMessage) return
  error.value = errorMessage
  $router.replace({ query: {} })
})

onUnmounted(() => {
  $store.commit('SET_EMAIL', null)
  $store.commit('SET_PASSWORD', null)
})

const user = computed(() => $store.state.user)

const newPhoneNumber = ref('')
const name = ref('')
const acceptPolicies = ref(false)
const emailOptIn = ref(true)
const acceptRequire = ref(false)
const phoneNumberModalOpen = ref(false)
const showIntelTelInput = ref(false)
const error = ref(null)

const analytics = computed(() => $store.state.analytics)

function generateCaptchaToken() {
  // This is hardcoded instead of using `getRecaptchaExpectedAction` because we can't get
  // the expected action from the URL for Google & Twitter auth, since the request is sent
  // to their servers instead of ours
  return safeGenerateRecaptchaToken('signup')
}

const $toast = useToast()

const props = defineProps({
  withAcceptPolicies: Boolean,
  ctaText: String,
  modelIds: {
    type: Array,
    default: () => [],
  },
  redirectPath: String,
  validationOpen: Boolean,
})

const { withAcceptPolicies, ctaText, modelIds, redirectPath } = toRefs(props)

function checkAcceptPolicies() {
  if (withAcceptPolicies.value && !acceptPolicies.value) {
    acceptRequire.value = true
    error.value = 'Please confirm you accept our policies'
    return false
  }
  acceptRequire.value = false
  return true
}

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const emit = defineEmits(['registered', 'update:validationOpen'])

const utmParams = computed(() => $store.state.utm)
const freeGeneratedImages = computed(() => $store.state.freeGeneratedImages)

async function google() {
  error.value = null

  const loader = $loading.show()

  const captchaToken = await generateCaptchaToken()
    .then(() => loader.hide())
    .catch((e) => {
      loader.hide()
      throw e
    })

  window.location.href = googleAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: redirectPath.value,
    ...(freeGeneratedImages.value &&
      freeGeneratedImages.value.length > 0 && {
        freeGeneratedImageId: freeGeneratedImages.value[0].id,
      }),
    ...(tempGender?.value && { gender: tempGender.value }),
  })
}

async function twitter() {
  error.value = null

  const loader = $loading.show()

  const captchaToken = await generateCaptchaToken()
    .then(() => loader.hide())
    .catch((e) => {
      loader.hide()
      throw e
    })

  window.location.href = twitterAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: props.redirectPath,
    ...(freeGeneratedImages.value &&
      freeGeneratedImages.value.length > 0 && {
        freeGeneratedImageId: freeGeneratedImages.value[0].id,
      }),
    ...(tempGender?.value && { gender: tempGender.value }),
  })
}

async function register() {
  error.value = null
  // if (!checkAcceptPolicies()) return

  const loader = $loading.show()

  const captchaToken = await generateCaptchaToken()

  try {
    const user = await $axios({
      method: 'post',
      url: '/auth/signup',
      data: {
        email: email.value,
        name: name.value.split(' ')[0],
        password: password.value,
        modelIds: modelIds.value,
        emailOptIn: emailOptIn.value,
        ...(freeGeneratedImages.value &&
          freeGeneratedImages.value.length > 0 && {
            freeGeneratedImageId: freeGeneratedImages.value[0].id,
          }),
        ...(tempGender?.value && { gender: tempGender.value }),
      },
      params: {
        ...utmParams.value,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    }).then((res) => res.data)
    await $store.commit('SET_USER', user)
    $store.commit('SET_FREE_GENERATED_IMAGES', user.freeGeneratedImages)
    await $store.commit('SET_PREMADE_MODEL', null)

    if (user.smsVerificationRequired) {
      loader.hide()
      return emit('update:validationOpen', true)
    }

    emit('registered')
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    error.value = message || e.response.status
  } finally {
    loader.hide()
  }
}

async function changePhoneNumber() {
  const loader = $loading.show()

  try {
    const { message } = await $axios({
      method: 'post',
      url: '/sms-verification/change-number',
      data: {
        phoneNumber: newPhoneNumber.value,
      },
    }).then((res) => res.data)

    $store.commit('SET_USER', {
      ...$store.state.user,
      phoneNumber: newPhoneNumber.value,
    })
    newPhoneNumber.value = ''
    updatePhoneNumberModalOpen(false)
    $toast.success(message)
  } catch (e) {
    const message = e?.response?.data?.message
    error.value = message || 'Unable to perform action. Please try again later.'
    $toast.error(error.value)
  } finally {
    loader.hide()
  }
}

const updatePhoneNumberModalOpen = (val) => {
  phoneNumberModalOpen.value = val

  if (val) {
    setTimeout(() => {
      showIntelTelInput.value = true
    }, 0)
  } else {
    setTimeout(() => {
      showIntelTelInput.value = false
    }, 300)
  }
}

const finishRegister = () => {
  emit('registered')
}

const validateModalShown = computed(() => {
  return (
    props.validationOpen || (user.value && user.value.smsVerificationRequired)
  )
})

watch(
  validateModalShown,
  (val) => {
    if (val) emit('update:validationOpen', val)
    else {
      ;(name.value = ''), (email.value = ''), (password.value = '')
      acceptPolicies.value = false
    }
  },
  { immediate: true },
)

const brandName = import.meta.env.VITE_BRAND_NAME

const handleNumberChange = (newNumber) => {
  newPhoneNumber.value = newNumber
}

function validateName(event) {
  name.value = event.target.value.replace(/[^a-zA-Z ]/g, '')
}
</script>

<template>
  <div class="mt-[25px] lg:mt-[30px]" v-if="!props.validationOpen">
    <div>
      <form
        action="#"
        method="POST"
        class="space-y-[20px] lg:space-y-[27px]"
        @submit.prevent="register"
        @click="error = null"
      >
        <div>
          <label
            for="first-name"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >First Name</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="first-name"
              v-model="name"
              @input="validateName"
              name="first-name"
              type="text"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label
            for="email"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >Email Address</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label
            for="password"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >Password</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>
        <div>
          <ButtonComponent
            type="submit"
            class="w-full py-[14px] rounded-[15px]"
          >
            {{ ctaText || 'Sign up' }}
          </ButtonComponent>
        </div>
        <ErrorAlert v-if="error" :errorText="error" />
      </form>
    </div>

    <div>
      <div
        class="my-[15px] lg:my-[30px] text-[#B1B5DB] text-sm flex items-center gap-2 lg:gap-4"
      >
        <div
          class="h-[1px] flex-1"
          style="
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0) 100%
            );
          "
        ></div>
        <p>Or continue with</p>
        <div
          class="h-[1px] flex-1"
          style="
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.3) 100%
            );
          "
        ></div>
      </div>
      <div class="flex gap-[9px]">
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="google"
        >
          <SvgIcon icon-id="google" class="h-6 w-6" alt="Google" />
          <span class="leading-6">Google</span>
        </a>
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="twitter"
        >
          <SvgIcon icon-id="twitter" class="h-6 w-6" alt="Google" />
          <span class="text-sm font-semibold leading-6">Twitter</span>
        </a>
      </div>
      <div class="text-xs md:text-sm text-[#f4f7fb] text-center mt-4 pb-2">
        By signing up, you confirm that you have read and accept the
        <router-link class="text-[#CC47FF]" to="/terms-of-service" @click.stop
          >Terms of Service</router-link
        >
        and
        <router-link class="text-[#CC47FF]" to="/privacy-policy" @click.stop
          >Privacy Policy</router-link
        >.
      </div>
    </div>
  </div>
  <SmsVerificationDialog
    v-else
    @finish-register="finishRegister"
    @open-phone-number-dialog="updatePhoneNumberModalOpen"
  />
  <Confirm
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[124px]"
    :open="phoneNumberModalOpen"
    :showDefault="false"
    type="confirm"
    @update:open="updatePhoneNumberModalOpen"
  >
    <form @submit.prevent="changePhoneNumber" class="m-1 bg-none">
      <h1 class="text-center">Change your phone number</h1>
      <IntlTelInput
        v-if="showIntelTelInput"
        @changeNumber="handleNumberChange"
        :options="{
          initialCountry: user.countryCode,
          containerClass: 'w-full mt-4 mb-6',
        }"
      />
      <ButtonComponent type="submit" class="py-3 px-6 w-full mt-2">
        Confirm
      </ButtonComponent>
    </form>
  </Confirm>
</template>

<style scoped>
input:not([type='checkbox']):focus {
  box-shadow: 0px 0px 0px 4px #cc47ff33;
}
</style>
